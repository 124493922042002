import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import theme from '../theme/theme';

const useStyles = createUseStyles(() => ({
  selfLink: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  heading: {
    display: 'flex',
    alignItems: 'baseline',
  },
  h1: {
    ...theme.typography.h1,
  },
  h2: {
    ...theme.typography.h2,
  },
  h3: {
    ...theme.typography.h3,
  },
  h4: {
    ...theme.typography.h4,
  },
  h5: {
    ...theme.typography.h5,
  },
  h6: {
    ...theme.typography.h6,
  },
  body: {
    ...theme.typography.p,
  },
  body2: {
    ...theme.typography.p,
    color: theme.palette.light.mono['500'],
    '&:': {
      marginTop: '0.2rem',
    },
  },
}));

const headingTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

const createHeadingId = children => {
  let headingText = '';
  if (Array.isArray(children)) {
    if (children.length > 1) return null;
    headingText = children[0];
  } else if (typeof children === 'string') {
    headingText = children;
  }

  return headingText
    .replace(/\s+/g, '-')
    .replace(/'/g, '')
    .toLowerCase();
};

const createHeadingProps = (children, joinedClassName) => ({
  id: createHeadingId(children),
  className: joinedClassName,
});

const Heading = ({ type, children, joinedClassName }) => {
  const classes = useStyles();
  const headingProps = createHeadingProps(children, joinedClassName);
  const SelfLink = () => (
    <a className={classes.selfLink} href={`#${headingProps.id}`}>
      {children}
    </a>
  );
  switch (type) {
    case 'h1':
      return (
        <h1 {...headingProps}>
          <SelfLink />
        </h1>
      );
    case 'h2':
      return (
        <h2 {...headingProps}>
          <SelfLink />
        </h2>
      );
    case 'h3':
      return (
        <h3 {...headingProps}>
          <SelfLink />
        </h3>
      );
    case 'h4':
      return (
        <h4 {...headingProps}>
          <SelfLink />
        </h4>
      );
    case 'h5':
      return (
        <h5 {...headingProps}>
          <SelfLink />
        </h5>
      );
    case 'h6':
      return (
        <h6 {...headingProps}>
          <SelfLink />
        </h6>
      );
    default:
      return null;
  }
};

const Typography = ({ type, children, className }) => {
  const classes = useStyles();

  const joinedClassName = [classes[type], className]
    .filter(item => !!item)
    .join(' ');

  if (headingTags.includes(type)) {
    return (
      <Heading type={type} joinedClassName={joinedClassName}>
        {children}
      </Heading>
    );
  } else if (type === 'body') {
    return <p className={joinedClassName}>{children}</p>;
  } else if (type === 'body2') {
    return <p className={joinedClassName}>{children}</p>;
  }
};

Typography.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([...headingTags, 'body', 'body2']).isRequired,
};

export default Typography;

export const HeadingOne = ({ children, className }) => (
  <Typography type="h1" className={className}>
    {children}
  </Typography>
);
export const HeadingTwo = ({ children, className, ...other }) => (
  <Typography type="h2" className={className}>
    {children}
  </Typography>
);
export const HeadingThree = ({ children, className }) => (
  <Typography type="h3" className={className}>
    {children}
  </Typography>
);
export const HeadingFour = ({ children, className }) => (
  <Typography type="h4" className={className}>
    {children}
  </Typography>
);
export const HeadingFive = ({ children, className }) => (
  <Typography type="h5" className={className}>
    {children}
  </Typography>
);
export const HeadingSix = ({ children, className }) => (
  <Typography type="h6" className={className}>
    {children}
  </Typography>
);
export const Body = ({ children, className }) => (
  <Typography type="body" className={className}>
    {children}
  </Typography>
);
export const BodyTwo = ({ children, className }) => (
  <Typography type="body2" className={className}>
    {children}
  </Typography>
);

[
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  HeadingFour,
  HeadingFive,
  HeadingSix,
  Body,
  BodyTwo,
].forEach(item => {
  item.propTypes = {
    className: PropTypes.string,
  };
});
