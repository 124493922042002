import spacing from './spacing';

const headingTypography = {
  marginBottom: '0.4rem',
  marginTop: '1.8rem',
  fontFamily: ['Raleway', 'sans-serif'],
  fontWeight: 'bold',
};

export const bodyTypography = {
  fontFamily: ['Open Sans', 'sans-serif'],
  lineHeight: 1.6,
  fontSize: '1rem',
  marginBottom: spacing.blockSpacing,
};

export const codeTypography = {
  borderRadius: '4px',
  fontFamily: ['"Fira Code"', 'monospace'],
  fontSize: '0.9rem',
  lineHeight: 1.4,
};

export default {
  h1: {
    ...headingTypography,
    fontSize: '2rem',
  },
  h2: {
    ...headingTypography,
    fontSize: '1.6rem',
  },
  h3: {
    ...headingTypography,
    fontSize: '1.3rem',
  },
  h4: { ...headingTypography },
  h5: { ...headingTypography },
  h6: { ...headingTypography },
  p: {
    ...bodyTypography,
    fontSize: '1rem',
  },
};
