import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(() => ({
  main: {
    maxWidth: '40rem',
    margin: 'auto',
    padding: '0 1rem',
  },
}));

export default ({ children }) => {
  const classes = useStyles();
  return <main className={classes.main}>{children}</main>;
};
